import { formatName } from './../util';

const GtmParametersBuilder = require('./gtmParametersBuilder');
const GtmCheckoutBuilder = require('./gtmCheckoutBuilder');
const GtmEcommBuilder = require('./gtmEcommBuilder');

function buildCheckoutOption(checkoutData) {
    const ecommData = new GtmEcommBuilder()
        .withCheckoutOption(checkoutData)
        .build();

    return new GtmParametersBuilder()
        .withEvent('ecomCheckoutOption')
        .withEcommerce(ecommData)
        .build();
}

function buildCheckout(checkoutData) {
    const ecommData = new GtmEcommBuilder()
        .withCheckout(checkoutData)
        .build();

    return new GtmParametersBuilder()
        .withEvent('ecomCheckout')
        .withEcommerce(ecommData)
        .build();
}

/**
 * @description Function for pushing GA4 event after clicking a CTA on second step of new checkout
 */
function pushMembershipStepCTAEvent() {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'next_step',
        type: 'membership checkout',
        step_name: 'select membership',
        step_index: 2,
    });
}

/**
 * @description Function for pushing GA4 event after selecting a club on new checkout
 */
function pushSelectClubEventNew() {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'precheckout',
        step: '2',
        component_name: 'select club',
    });
}

function pushMembershipViewDetailsEvent(membershipType) {
    if (!membershipType) {
        return;
    }

    const event = new GtmParametersBuilder()
        .withEvent('view_details')
        .withParameter('type', 'memberships')
        .withParameter('membership', membershipType.toLowerCase())
        .build();

    window.dataLayer.push(event);
}

function pushMembershipHideDetailsEvent(membershipType) {
    if (!membershipType) {
        return;
    }

    const event = new GtmParametersBuilder()
        .withEvent('hide_details')
        .withParameter('type', 'memberships')
        .withParameter('membership', membershipType.toLowerCase())
        .build();

    window.dataLayer.push(event);
}

function buildPurchaseOption(checkoutData, user) {
    const ecommData = new GtmEcommBuilder()
        .withPurchaseOption(checkoutData)
        .build();

    return new GtmParametersBuilder()
        .withEvent('ecomPurchase')
        .withEcommerce(ecommData)
        .withUser(user)
        .build();
}

function pushPersonalDetailsEvent(products, option) {
    if (!products) {
        return;
    }

    const checkoutData = new GtmCheckoutBuilder()
        .withActionField({ step: 1, option })
        .withProducts(products.map(product => formatName(product)))
        .build();

    const gtmParams = buildCheckout(checkoutData);

    window.dataLayer.push(gtmParams);
}

function pushAddressInfoLoadedEvent(products, option) {
    if (!products) {
        return;
    }

    const checkoutData = new GtmCheckoutBuilder()
        .withActionField({ step: 2, option })
        .withProducts(products.map(product => product))
        .build();

    const gtmParams = buildCheckout(checkoutData);

    window.dataLayer.push(gtmParams);
}

function pushPaymentStepVisited(products, option) {
    if (!products) {
        return;
    }

    const checkoutData = new GtmCheckoutBuilder()
        .withActionField({ step: 3, option })
        .withProducts(products.map(product => product))
        .build();
    const gtmParams = buildCheckout(checkoutData);

    window.dataLayer.push(gtmParams);
}

function pushPaymentMethodLoaded(products, option) {
    if (!products) {
        return;
    }

    const checkoutData = new GtmCheckoutBuilder()
        .withActionField({ step: 4, option })
        .withProducts(products.map(product => product))
        .build();
    const gtmParams = buildCheckout(checkoutData);

    window.dataLayer.push(gtmParams);
}

function pushConfirmationPageLoaded(actionField, products, marketingID, user, userID) {
    if (!products) {
        return;
    }

    const checkoutData = new GtmCheckoutBuilder()
        .withActionField(actionField)
        .withProducts(products.map(product => formatName(product)))
        .withMarketingID(marketingID)
        .build();
    const gtmParams = buildPurchaseOption(checkoutData, user);
    // Idetify user for Tracedock
    window.dataLayer.push({ userId: userID });
    window.dataLayer.push(gtmParams);
    localStorage.removeItem('selectedClub');
    localStorage.removeItem('selectedMembershipType');
    localStorage.removeItem('selectedMembershipDuration');
    localStorage.removeItem('selectedAddOns');
}

/**
 * Handles purchase data layer event
 * @param {Object} data - Event data
 * @param {Object} userData - User data
 * @param {Object} actionData - Action data
 */
function pushConfirmationPageLoadedNew(data, userData, actionData) {
    if (!(data && data.items)) {
        return;
    }

    const membership = data.items.find(item => item.item_category === 'subscription');

    if (!membership) {
        return;
    }

    const user = {
        user_email: userData.user_email,
        user_phone_number: userData.user_phone_number,
        user_first_name: userData.user_first_name,
        user_last_name: userData.user_last_name,
        user_gender: userData.user_gender,
        user_city: userData.user_city,
        user_country: userData.user_country_code,
        user_street: userData.user_street,
        user_postal_code: userData.user_zip_code,
        user_age_group: userData.user_age_group,
        member_id: actionData.peopleId,
    };

    const club = {
        club_name: data.club_name,
        club_id: data.club_id,
        club_services: data.club_services,
        club_founding_member: data.club_founding_member,
    };

    const ecommerce = {
        transaction_id: actionData.id,
        affiliation: actionData.affiliation,
        order_amount: actionData.orderAmount,
        value: actionData.value,
        currency: data.currency,
        payment_type: actionData.paymentType,
        payment_provider: actionData.paymentProvider,
        items: data.items,
    };

    window.dataLayer = window.dataLayer || [];

    const event = new GtmParametersBuilder()
        .withEvent('purchase')
        .withParameter('membership', membership.item_name)
        .withParameter('membership_duration', membership.item_variant)
        .withParameter('payment_interval', data.payment_interval)
        .withParameter('user', user)
        .withParameter('club', club)
        .withParameter('ecommerce', ecommerce)
        .build();

    window.dataLayer.push(event);
}

function pushOriginalLocation(originalLocation) {
    const event = new GtmParametersBuilder()
        .withParameter('original_location', originalLocation)
        .build();

    window.dataLayer.push(event);
}

function pushPageView(data) {
    if (!data) {
        return;
    }

    const events = [
        new GtmParametersBuilder()
            .withParameter('page', null)
            .build(),
        new GtmParametersBuilder()
            .withEvent('page_view')
            .withPage(data)
            .withTraffic(data)
            .build(),
    ];

    events.forEach((event) => {
        window.dataLayer.push(event);
    });
}


/**
 * Push information for gtm datalayer
 * @description - Function for pushing datalayer when membership is added to cart
 * @param {Object} data - The cart data
 */
function pushMembershipSelectedToCart(data) {
    if (!data) {
        return;
    }

    // eslint-disable-next-line no-param-reassign
    data.items = data.items.find(item => item.item_category === 'subscription');
    window.dataLayer = window.dataLayer || [];
    const events = [
        new GtmParametersBuilder()
            .withParameter('ecommerce', null)
            .build(),
        new GtmParametersBuilder()
            .withEvent('add_to_cart')
            .withEcommerce(data)
            .build(),
    ];
    events.forEach((event) => {
        window.dataLayer.push(event);
    });
}

/**
 * Push information for gtm datalayer
 * @description - Function for pushing datalayer when addon is added to cart
 * @param {Object} data - The Addon data
 */
function pushSelectedAddOnData(data) {
    if (!data) {
        return;
    }

    data.items = data.items.slice(-1); // eslint-disable-line no-param-reassign
    window.dataLayer = window.dataLayer || [];
    const events = [
        new GtmParametersBuilder()
            .withParameter('ecommerce', null)
            .build(),
        new GtmParametersBuilder()
            .withEvent('add_to_cart')
            .withEcommerce(data)
            .build(),
    ];
    events.forEach((event) => {
        window.dataLayer.push(event);
    });
}

/**
 * @description Function for creating begin_checkout event on entering step 4 of new checkout
 * @param {Object} products - Products object consisting of currency (3-letter format) and products from basket
 */
function pushPersonalDetailsEventNew(products) {
    if (!products) {
        return;
    }

    let productsOb;

    if (typeof products === 'string') {
        productsOb = JSON.parse(products);
    } else {
        productsOb = products;
    }

    window.dataLayer.push({ ecommerce: null });

    const event = new GtmParametersBuilder()
        .withEvent('begin_checkout', null)
        .withParameter('ecommerce', productsOb)
        .build();

    window.dataLayer.push(event);
}

/**
 * Push information for gtm datalayer
 * @description - Function for pushing datalayer when product is remove from cart
 * @param {Object} data - The cart data
 * @param {boolean} isOnlyAddonsRemove - true if delete only of addons from cart, false otherwise
 */
function pushRemovedProductFromCart(data, isOnlyAddonsRemove) {
    if (!data) {
        return;
    }

    if (isOnlyAddonsRemove) {
        // eslint-disable-next-line no-param-reassign
        data.items = data.items.filter(item => item.item_category === 'add-on');
    }

    window.dataLayer = window.dataLayer || [];
    const events = [
        new GtmParametersBuilder()
            .withParameter('ecommerce', null)
            .build(),
        new GtmParametersBuilder()
            .withEvent('remove_from_cart')
            .withEcommerce(data)
            .build(),
    ];
    events.forEach((event) => {
        window.dataLayer.push(event);
    });
}

/**
 * @description Function for creating to_payment_info event on entering step 5 of new checkout
 * @param {Object} products - Products object consisting of currency (3-letter format) and products from basket
 * @param {boolean} promotionsOn - true if promotion checkbox is checked, false otherwise
 * @param {boolean} optimizeOn - true if optimize checkbox is checked, false otherwise
 */
function pushPaymentStepVisitedNew(products, promotionsOn, optimizeOn) {
    if (!products) {
        return;
    }

    let productsOb;

    if (typeof products === 'string') {
        productsOb = JSON.parse(products);
    } else {
        productsOb = products;
    }

    window.dataLayer.push({ ecommerce: null });

    productsOb.checkbox_optimization = optimizeOn;
    productsOb.checkbox_receive_promotions = promotionsOn;

    const event = new GtmParametersBuilder()
        .withEvent('to_payment_info', null)
        .withParameter('ecommerce', productsOb)
        .build();

    window.dataLayer.push(event);
}

/**
 * @description Function for creating two events after submiting payment form: add_payment_info and to_payment_provider
 * @param {Object} data - data to push to gtm
 */
function pushPaymentProviderAndInfo(data) {
    if (!data) {
        return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const events = [
        new GtmParametersBuilder()
            .withEvent('add_payment_info')
            .withParameter('ecommerce', data)
            .build(),
        new GtmParametersBuilder()
            .withEvent('to_payment_provider')
            .build(),
    ];
    events.forEach((event) => {
        window.dataLayer.push(event);
    });
}

/**
 * Push information for gtm datalayer
 * @description - Function for pushing datalayer when an error occurs in the process
 * @param {Object} errorType - Error message type
 * @param {Object} message - Error message
 */
function pushCheckoutErrors(errorType, message) {
    window.dataLayer = window.dataLayer || [];
    const errorObject = {
        error_type: errorType,
        error_message: message,
    };
    const events = [
        new GtmParametersBuilder()
            .withParameter('error', null)
            .build(),
        new GtmParametersBuilder()
            .withEvent('error')
            .withError(errorObject)
            .build(),
    ];
    events.forEach((event) => {
        window.dataLayer.push(event);
    });
}

/**
 * @description Function for filtering only removed product from array of all removed products
 * @param {Object} gtmRemovedProductsOb -  gtmRemovedProducts Object
 * @param {string} productName - product name to filter
 * @returns {?Object} - object holding reduced items property or null
*/
function removeSelectedProduct(gtmRemovedProductsOb, productName) {
    if (!gtmRemovedProductsOb) {
        return null;
    }

    const filteredItemsForRemove = gtmRemovedProductsOb.items
        .filter(item => item.item_name === productName);
    const gtmRemoveProductsReduced = {
        ...gtmRemovedProductsOb,
        items: filteredItemsForRemove,
    };

    return gtmRemoveProductsReduced;
}

/**
 * @description Function for handling filter select and de-select events
 * @param {string} eventName - name of event
 * @param {string} filterName - name of filter selected/de-selected
 */
function pushFilterClickEvent(eventName, filterName) {
    window.dataLayer = window.dataLayer || [];
    const event = new GtmParametersBuilder()
        .withEvent(eventName)
        .withParameter('type', filterName)
        .build();

    window.dataLayer.push(event);
}

/**
 * @description Function for handling view selection event
 * @param {string} viewTypeName - view type name
 */
function pushViewTypeSelectEvent(viewTypeName) {
    window.dataLayer = window.dataLayer || [];
    const event = new GtmParametersBuilder()
        .withEvent('change_view')
        .withParameter('type', viewTypeName)
        .build();

    window.dataLayer.push(event);
}

function pushPaymentMethodSelected(option) {
    if (!option) {
        return;
    }
    const checkoutData = new GtmCheckoutBuilder()
        .withActionField({ step: 4, option })
        .build();
    const gtmParams = buildCheckoutOption(checkoutData);

    window.dataLayer.push(gtmParams);
}

/**
 * Handles select_membership data layer event
 * @param {?Object} data - Event data
 * @param {boolean} isFoundingMember - True if the current club is a founding member, False otherwise
 */
function pushSelectMembershipEvent(data, isFoundingMember) {
    if (!(data && data.items)) {
        return;
    }

    const membership = data.items.find(item => item.item_category === 'subscription');

    if (!membership) {
        return;
    }

    const clubName = membership.item_brand ? membership.item_brand.toLowerCase() : '';
    const clubServices = membership.item_category2 ? membership.item_category2.toLowerCase() : '';

    window.dataLayer = window.dataLayer || [];

    const event = new GtmParametersBuilder()
        .withEvent('select_membership')
        .withParameter('membership', membership.item_name)
        .withParameter('payment_interval', membership.payment_interval)
        .withParameter('club', {
            club_name: clubName,
            club_id: membership.item_category3,
            club_services: clubServices,
            club_founding_member: isFoundingMember,
        })
        .build();

    window.dataLayer.push(event);
}

export {
    pushPersonalDetailsEvent,
    pushAddressInfoLoadedEvent,
    pushPaymentStepVisited,
    pushPaymentMethodLoaded,
    pushOriginalLocation,
    pushPageView,
    pushMembershipSelectedToCart,
    pushSelectedAddOnData,
    pushPersonalDetailsEventNew,
    pushRemovedProductFromCart,
    pushPaymentStepVisitedNew,
    pushPaymentProviderAndInfo,
    pushConfirmationPageLoaded,
    pushConfirmationPageLoadedNew,
    pushCheckoutErrors,
    pushSelectClubEventNew,
    pushMembershipStepCTAEvent,
    removeSelectedProduct,
    pushFilterClickEvent,
    pushViewTypeSelectEvent,
    pushMembershipViewDetailsEvent,
    pushMembershipHideDetailsEvent,
    pushPaymentMethodSelected,
    pushSelectMembershipEvent,
};
